import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Image from "./image"
import Hidden from "@material-ui/core/Hidden"
import Bar from "./bar"

const AboutPanel = () => {
  return (
    <Grid container spacing={3} style={{ minHeight: 500, paddingTop: 50 }}>
      <Hidden xsDown>
        <Grid
          item={true}
          sm={6}
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
          style={{ padding: 10 }}
        >
          <div style={{ width: "100%" }}>
            <Image
              alt="Virtual Healthcare for you"
              filename="about-illustration.png"
            />
          </div>
        </Grid>
      </Hidden>
      <Grid
        item={true}
        xs={12}
        sm={6}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{ paddingLeft: 40, paddingRight: 40 }}
      >
        <Typography variant="h4" color="inherit">
          Punzalan Law is your place for legal advice in Winnipeg
        </Typography>
        <Bar />
        <Typography
          variant="subtitle1"
          style={{ marginBottom: 30, marginTop: 20 }}
        >
          When you are seeking out the help of a commercial, real estate, or
          family lawyer for legal advice in Winnipeg and surrounding areas,
          Punzalan Law should be your first choice. Whether you are buying or
          selling a home, starting a business, incorporating or drafting your
          last will and testament or going through a divorce or separation, we
          approach each matter with our full attention and dedication. We aim to
          protect your interests and exceed your expectations. We represent a
          wide variety of clients, from first-time homebuyers to corporations.
          We welcome business from anyone and everyone.
        </Typography>
      </Grid>
    </Grid>
  )
}
export default AboutPanel
