import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Image from "./image"
import Hidden from "@material-ui/core/Hidden"
import Bar from "./bar"
import { List, ListItem } from "@material-ui/core"

const AffiliationsPanel = () => {
  return (
    <Grid container spacing={3} style={{ minHeight: 500 }}>
      <Grid
        item={true}
        xs={12}
        sm={6}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{ paddingLeft: 40, paddingRight: 40 }}
      >
        <Typography variant="h4" color="inherit">
          Professional Affiliations{" "}
        </Typography>
        <Bar />
        <Typography
          variant="subtitle1"
          style={{ marginBottom: 30, marginTop: 20 }}
        >
          <List>
            <ListItem
              href="https://lawsociety.mb.ca/"
              component="a"
              target="__blank"
            >
              Law Society of Manitoba
            </ListItem>
            <ListItem
              href="https://www.cba-mb.ca/Home"
              component="a"
              target="__blank"
            >
              Canadian Bar Association
            </ListItem>
          </List>
        </Typography>
      </Grid>

      <Hidden xsDown>
        <Grid
          item={true}
          sm={6}
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
          style={{ padding: 10 }}
        >
          <div style={{ width: "100%" }}>
            <Image
              alt="Virtual Healthcare for you"
              filename="apps-panel-image.png"
            />
          </div>
        </Grid>
      </Hidden>
    </Grid>
  )
}
export default AffiliationsPanel
