import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import ServiceCard from "./service-card"
import Bar from "./bar"

const Services = () => {
  let services = [
    {
      id: 1,
      image: "search-doctor-icon.png",
      title: "Commercial Law",
      description:
        "We practice in many areas within commercial law from commercial leasing to consulting agreements.",
    },
    {
      id: 2,
      image: "online-pharmacy-icon.png",
      title: "Residential Real Estate",
      description:
        "Legal assistance to assist in your purchase or sale of property.",
    },
    {
      id: 3,
      image: "consultation-icon.png",
      title: "Last Wills and Powers of Attorney",
      description:
        "We assist in preparation of last wills and powers of attorney.",
    },
    {
      id: 4,
      image: "details-info-icon.png",
      title: "Corporate Law",
      description:
        "Timely delivery of cost-effective filing and registration services for companies and organizations.",
    },
    {
      id: 5,
      image: "tracking-icon.png",
      title: "Construction and Development",
      description: "We offer a variety of construction-related services.",
    },
    {
      id: 5,
      image: "emergency-care-icon.png",
      title: "Domestic Law",
      description:
        "We assist in many issues within domestic law from prenuptial agreements to divorce and property divison.",
    },
  ]

  return (
    <Grid
      container
      spacing={3}
      style={{
        textAlign: "center",
        minHeight: 200,
        padding: 10,
      }}
    >
      <Grid
        item
        xs={12}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Typography variant="h5">Our Services</Typography>
        <Bar />
        <Typography variant="subtitle1" style={{ margin: 30 }}>
          We offer several legal services to help Manitoba residents.
        </Typography>
      </Grid>

      {services.map(service => (
        <Grid item xs={12} sm={4} key={service.id}>
          <ServiceCard service={service} />
        </Grid>
      ))}

      <Grid item xs={12}>
        <Button variant="outlined" color="primary" size="large">
          Learn More
        </Button>
      </Grid>
    </Grid>
  )
}
export default Services
